export const apiUrl = "https://apierp.mynet.co.id";
// export const apiUrl = "https://apimynet.hulwa.co.id";
// export const apiv3Url = "http://api-mynet-v3.local";
// export const apiv3Url = "https://apimynet2.hulwa.co.id";
export const apiv3Url = "https://apierp2.mynet.co.id";
// export const urlClient = "http://127.0.0.1:8000";
// export const urlClient = "https://user.hulwa.co.id";
export const urlClient = "https://user.mynet.co.id";

export const admin_price = 2500;

export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const salesRetailFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "Sales Retail") || (roleName === "Admin Teknisi Retail")
}
export const salesJatinangorFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "Sales Jatinangor")
}
export const salesCorporateFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "Sales Corporate");
}
export const teknisiRetailFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "Teknisi Retail") || (roleName === "Teknisi Corporate") || (roleName === "Admin Teknisi Retail") || (roleName === "IKR") || (roleName === "Lead Backbone") || (roleName === "Teknisi Backbone")
}
export const financeHrdFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "HRD") || (roleName === "Finance")
}
export const teknisiCorporateFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "Teknisi Retail") || (roleName === "Teknisi Corporate")
}
export const teknisiNOCFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "NOC")
}
export const teknisiJatinangorFilter = x => {
    const roleName = x.role_info ? x.role_info.role_name : x.role.name;
    return (roleName === "Teknisi Jatinangor")
}