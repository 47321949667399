import { createSlice } from "@reduxjs/toolkit";

const initialReturState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  entitiesTraffic: null,
  exportData: null,
  currency: null,
  lastError: null,
  returForEdit: undefined,
  returForValid: undefined,
  returForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const returSlice = createSlice({
  name: "retur",
  initialState: initialReturState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		returFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		exportRetur: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
		exportFetchedRetur: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = export_data;
      state.totalCount = totalCount;
    },
		trafficRetur: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesTraffic = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
    returCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.retur.push(action.payload.retur);
    },
    returDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.retur.filter(el => el.retur_id !== action.payload.id);
    },
    returDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.retur.filter(el => el.retur_id !== action.payload.id);
    },
    getRetur: (state, action) => {
      state.actionsLoading = false;
      state.returForEdit = action.payload.returForEdit;
      state.error = null;
    },
    getValidRetur: (state, action) => {
      state.actionsLoading = false;
      state.returForValid = action.payload.returForValid;
      state.error = null;
    },
    getExportRetur: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.returForExport === undefined) {
        state.returForExport = [];
      }else{
        state.returForExport.push(action.payload.returForExport);
      }
      state.error = null;
    },
    // updateProduct
    returUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.retur = state.entities.retur.map(entity => {
        if (entity.retur_id === action.payload.retur.retur_id) {
          return action.payload.retur;
        }
        return entity;
      });
    },
  },
});
