import { createSlice } from "@reduxjs/toolkit";

const initialTransferState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  entitiesTraffic: null,
  exportData: null,
  currency: null,
  lastError: null,
  transferForEdit: undefined,
  transferForValid: undefined,
  transferForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const transferSlice = createSlice({
  name: "transfer",
  initialState: initialTransferState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		transferFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		exportTransfer: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
		exportFetchedTransfer: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = export_data;
      state.totalCount = totalCount;
    },
		trafficTransfer: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesTraffic = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
    transferCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.transfer.push(action.payload.transfer);
    },
    transferDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.transfer.filter(el => el.transfer_id !== action.payload.id);
    },
    transferDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.transfer.filter(el => el.transfer_id !== action.payload.id);
    },
    getTransfer: (state, action) => {
      state.actionsLoading = false;
      state.transferForEdit = action.payload.transferForEdit;
      state.error = null;
    },
    getValidTransfer: (state, action) => {
      state.actionsLoading = false;
      state.transferForValid = action.payload.transferForValid;
      state.error = null;
    },
    getExportTransfer: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.transferForExport === undefined) {
        state.transferForExport = [];
      }else{
        state.transferForExport.push(action.payload.transferForExport);
      }
      state.error = null;
    },
    // updateProduct
    transferUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.transfer = state.entities.transfer.map(entity => {
        if (entity.transfer_id === action.payload.transfer.transfer_id) {
          return action.payload.transfer;
        }
        return entity;
      });
    },
  },
});
