import { createSlice } from "@reduxjs/toolkit";

const initialOdpLocationState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  odpLocationForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const odpLocationSlice = createSlice({
  name: "odpLocation",
  initialState: initialOdpLocationState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		odpLocationFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		odpLocationAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    odpLocationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.odpLocation.push(action.payload.odpLocation);
    },
    odpLocationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.odpLocation.filter(el => el.odpLocation_id !== action.payload.id);
    },
    getOdpLocation: (state, action) => {
      state.actionsLoading = false;
      state.odpLocationForEdit = action.payload.odpLocationForEdit;
      state.error = null;
    },
    // updateProduct
    odpLocationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.odpLocation = state.entities.odpLocation.map(entity => {
        if (entity.odpLocation_id === action.payload.odpLocation.odpLocation_id) {
          return action.payload.odpLocation;
        }
        return entity;
      });
    },
  },
});
