import { createSlice } from "@reduxjs/toolkit";

const initialWhVendorState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  whVendorForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const whVendorSlice = createSlice({
  name: "whVendor",
  initialState: initialWhVendorState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		whVendorFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		whVendorAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    whVendorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.whVendor.push(action.payload.whVendor);
    },
    whVendorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.whVendor.filter(el => el.wh_vendor_id !== action.payload.id);
    },
    getWhVendor: (state, action) => {
      state.actionsLoading = false;
      state.whVendorForEdit = action.payload.whVendorForEdit;
      state.error = null;
    },
    // updateProduct
    whVendorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.whVendor = state.entities.whVendor.map(entity => {
        if (entity.wh_vendor_id === action.payload.whVendor.wh_vendor_id) {
          return action.payload.whVendor;
        }
        return entity;
      });
    },
  },
});
