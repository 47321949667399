import { createSlice } from "@reduxjs/toolkit";

const initialCarryState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  entitiesTraffic: null,
  exportData: null,
  currency: null,
  lastError: null,
  carryForEdit: undefined,
  carryForValid: undefined,
  carryForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const carrySlice = createSlice({
  name: "carry",
  initialState: initialCarryState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		carryFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		exportCarry: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
		exportFetchedCarry: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = export_data;
      state.totalCount = totalCount;
    },
		trafficCarry: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesTraffic = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
    carryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.carry.push(action.payload.carry);
    },
    carryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.carry.filter(el => el.carry_id !== action.payload.id);
    },
    carryDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.carry.filter(el => el.carry_id !== action.payload.id);
    },
    getCarry: (state, action) => {
      state.actionsLoading = false;
      state.carryForEdit = action.payload.carryForEdit;
      state.error = null;
    },
    getValidCarry: (state, action) => {
      state.actionsLoading = false;
      state.carryForValid = action.payload.carryForValid;
      state.error = null;
    },
    getExportCarry: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.carryForExport === undefined) {
        state.carryForExport = [];
      }else{
        state.carryForExport.push(action.payload.carryForExport);
      }
      state.error = null;
    },
    // updateProduct
    carryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.carry = state.entities.carry.map(entity => {
        if (entity.carry_id === action.payload.carry.carry_id) {
          return action.payload.carry;
        }
        return entity;
      });
    },
  },
});
