import { createSlice } from "@reduxjs/toolkit";

const initialPonState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  ponForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const ponSlice = createSlice({
  name: "pon",
  initialState: initialPonState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		ponFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		ponAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    ponCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.pon.push(action.payload.pon);
    },
    ponDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.pon.filter(el => el.pon_id !== action.payload.id);
    },
    getPon: (state, action) => {
      state.actionsLoading = false;
      state.ponForEdit = action.payload.ponForEdit;
      state.error = null;
    },
    // updateProduct
    ponUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.pon = state.entities.pon.map(entity => {
        if (entity.pon_id === action.payload.pon.pon_id) {
          return action.payload.pon;
        }
        return entity;
      });
    },
  },
});
