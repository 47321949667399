import { createSlice } from "@reduxjs/toolkit";

const initialOdpState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  odpForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const odpSlice = createSlice({
  name: "odp",
  initialState: initialOdpState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		odpFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		odpAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    odpCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.odp.push(action.payload.odp);
    },
    odpDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.odp.filter(el => el.odp_id !== action.payload.id);
    },
    getOdp: (state, action) => {
      state.actionsLoading = false;
      state.odpForEdit = action.payload.odpForEdit;
      state.error = null;
    },
    // updateProduct
    odpUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.odp = state.entities.odp.map(entity => {
        if (entity.odp_id === action.payload.odp.odp_id) {
          return action.payload.odp;
        }
        return entity;
      });
    },
  },
});
