import { createSlice } from "@reduxjs/toolkit";

const initialInstalasiState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  instalasiForEdit: undefined,
  instalasiAssetValid: undefined,
  instalasiPortValid: undefined,
  instalasiForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const instalasiSlice = createSlice({
  name: "instalasi",
  initialState: initialInstalasiState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		instalasiFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		instalasiExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.instalasiForExport = entities;
      state.totalCount = totalCount;
    },
    instalasiCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.instalasi.push(action.payload.instalasi);
    },
    instalasiDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.instalasi.filter(el => el.instalasi_id !== action.payload.id);
    },
    getInstalasi: (state, action) => {
      state.actionsLoading = false;
      state.instalasiForEdit = action.payload.instalasiForEdit;
      state.error = null;
    },
    getValidAsset: (state, action) => {
      state.actionsLoading = false;
      state.instalasiAssetValid = action.payload.instalasiAssetValid;
      state.error = null;
    },
    getValidPort: (state, action) => {
      state.actionsLoading = false;
      state.instalasiPortValid = action.payload.instalasiPortValid;
      state.error = null;
    },
    getExportInstalasi: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.instalasiForExport === undefined) {
        state.instalasiForExport = [];
      }else{
        state.instalasiForExport.push(action.payload.instalasiForExport);
      }
      state.error = null;
    },
    // updateProduct
    instalasiUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.instalasi = state.entities.instalasi.map(entity => {
        if (entity.instalasi_id === action.payload.instalasi.instalasi_id) {
          return action.payload.instalasi;
        }
        return entity;
      });
    },
  },
});
