import { createSlice } from "@reduxjs/toolkit";

const initialPopLocationState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  popLocationForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const popLocationSlice = createSlice({
  name: "popLocation",
  initialState: initialPopLocationState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		popLocationFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		popLocationAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    popLocationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.popLocation.push(action.payload.popLocation);
    },
    popLocationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.popLocation.filter(el => el.popLocation_id !== action.payload.id);
    },
    getPopLocation: (state, action) => {
      state.actionsLoading = false;
      state.popLocationForEdit = action.payload.popLocationForEdit;
      state.error = null;
    },
    // updateProduct
    popLocationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.popLocation = state.entities.popLocation.map(entity => {
        if (entity.popLocation_id === action.payload.popLocation.popLocation_id) {
          return action.payload.popLocation;
        }
        return entity;
      });
    },
  },
});
