import { createSlice } from "@reduxjs/toolkit";

const initialPopState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  popForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const popSlice = createSlice({
  name: "pop",
  initialState: initialPopState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		popFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		popAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    popCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.pop.push(action.payload.pop);
    },
    popDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.pop.filter(el => el.pop_id !== action.payload.id);
    },
    getPop: (state, action) => {
      state.actionsLoading = false;
      state.popForEdit = action.payload.popForEdit;
      state.error = null;
    },
    // updateProduct
    popUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.pop = state.entities.pop.map(entity => {
        if (entity.pop_id === action.payload.pop.pop_id) {
          return action.payload.pop;
        }
        return entity;
      });
    },
  },
});
